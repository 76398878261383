// import React, { useEffect, useState } from 'react';
import React, { MouseEventHandler, useEffect, useState } from 'react';
// import HomepageBanner from "@assets/videos/GPA_Launch_HomeBanner.mp4";
import { Details, Images, List, UnorderedList } from '@core';
import { HeaderGradient } from '@src/components';
import { FaPlus } from 'react-icons/fa';
import { SOPDATA } from '@src/types/SOPindex';
import { getNewsRelease } from '@src/services';
import { API } from '@src/config';
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';
// import { HomeLearnMoreItem } from "@src/components";

export const RateAdjustment = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  // const getData = async () => {
  //   const fileDatas = await getFileData();
  //   setFile(fileDatas.data);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  const openAnimation = {
    animation: 'buttonOpening 250ms ease-in',
  };

  const closeAnimation = {
    animation: 'buttonClosing 270ms ease-out',
    animationFillMode: 'forwards',
  };

  const [showFaqsContent, setShowFaqsContent] = useState<boolean>(false);
  const [showRateAdjustmentsContent, setShowRateAdjustmentsContent] = useState<boolean>(false);
  const [showBillingSamplesContent, setBillingSamplesContent] = useState<boolean>(false);
  const [showPetitionsContent, setShowPetitionsContent] = useState<boolean>(false);
  const [showReportsContent, setShowReportsContentset] = useState<boolean>(false);

  const [reports, setReports] = useState<SOPDATA[]>([]);
  
  const getData = async () => {
    const fileDatas = await getNewsRelease();
  
    setReports(fileDatas.data);
  };
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-misc-orange phone:text-center phoneWide:text-left"
        rightColor="to-misc-yellow2"
        title="Håfa Adai to Brighter Days"
      />
      <div
        className="relative h-96"
        style={{
          minHeight: '350px',
          backgroundImage: `url(${Images.homeBannerFeb2025})`,
          backgroundPosition: 'left',
          backgroundSize: 'cover',
        }}
      >
        <div className="flex justify-center items-center h-full">
          <h1 className="mb-1 font-bold text-darkblue font-jubilat text-2xl text-center tabletWide:text-5xl  laptop:text-6xl desktop:text-6xl p-2 bg-darkerblue bg-opacity-80">
            <span className='block text-yellow2'>Håfa Adai</span>
            <span className='block text-white'>to Brighter Days</span>
          </h1>
        </div>
      </div>

      {/* Main Content Section */}
      <section className="flex flex-col w-full tabletWide:flex-row">
        {/* Grid Section */}
        <div className="p-4 w-full phoneLg:py-12 phoneLg:px-12 laptop:px-20 tabletWide:w-3/4 laptopSm:w-4/5 laptop:w-3/4">

          <p className="text-xl font-bold tablet:text-2xl laptopSm:text-3xl mb-4">
            Investing in Reliability
          </p>
          <p className='tablet:text-xl laptopSm:text-2xl my-8'>A Base Rate adjustment will support critical system upgrades, but lower fuel costs will help keep overall bills down.</p>

          <p className='laptopSm:text-lg my-4'>We're taking action to lower power bills for our island's families and businesses.</p>

          <p className='laptopSm:text-lg my-4'>If authorized by the Consolidated Commission on Utilities (CCU), GPA will petition the Guam Public Utilities Commission (PUC) for two proposed rate changes, effective September 1, 2025.</p>

          <div className="grid grid-cols-1 gap-2 my-10 laptop:my-20 w-full tablet:grid-cols-2 laptopSm:grid-cols-3 laptop:grid-cols-4">
            <div className="p-4 laptop:px-1">
              <div className="h-80 phoneWide:h-80 laptop:h-350px bg-darkblue rounded-2xl flex flex-col items-center justify-start">
                <p className="text-center text-yellow2 text-xl laptop:text-2xl font-semibold mt-4 laptop:mt-7">
                  <span className='block'>Lower Bills</span>
                  <span className='block'>Already in Effect</span>
                </p>
                <div className='w-full border-b border-2 border-white my-4 laptop:mt-7' />
                <p className="text-white text-md desktop:text-lg font-jubilat font-semibold mx-auto w-3/4">
                  The average monthly residential bill lowered by
                </p>
                <p className="text-yellow2 text-5xl laptop:text-4xl desktop:text-5xl font-jubilat font-semibold my-4 laptop:my-2 mx-auto w-3/4">
                  $53.00
                </p>
                <p className='text-white text-xs mx-auto w-3/4 '>
                  Based on 1000 kWh residential consumption.
                  <br />
                  Effective as of February 1, 2025
                </p>
              </div>
            </div>

            <div className="p-4 laptop:px-1">
              <div className="h-80 laptop:h-350px bg-darkblue rounded-2xl flex flex-col items-center justify-start">
                <p className="text-center text-yellow2 text-xl laptop:text-2xl font-semibold mt-4 laptop:mt-7">
                  <span className='block'>More Savings</span>
                  <span className='block'>on the Way</span>
                </p>
                <div className='w-full border-b border-2 border-white my-4 laptop:mt-7' />
                <p className="text-white text-md desktop:text-lg font-jubilat font-semibold mx-auto w-3/4">
                  Additional decrease on average monthly power bills of
                </p>
                <p className="text-yellow2 text-5xl laptop:text-4xl desktop:text-5xl font-jubilat font-semibold my-4 laptop:my-2 mx-auto w-3/4">
                  $42.00
                </p>
                <p className='text-white text-xs mx-auto w-3/4 '>
                  Effective September 1, 2025 (proposed)
                </p>
              </div>
            </div>

            <div className="p-4 laptop:px-1">
              <div className="h-80 laptop:h-350px bg-darkblue rounded-2xl flex flex-col items-center justify-start">
                <p className="text-center text-yellow2 text-xl laptop:text-2xl font-semibold mt-4 laptop:mt-7">
                  Smarter Energy,
                  <br />
                  Bigger Benefits
                </p>
                <div className='w-full border-b border-2 border-white my-4 laptop:mt-7' />
                <p className="text-white text-md desktop:text-lg font-jubilat font-semibold mx-auto w-3/4">
                  The Ukudu Power Plant will replace aging generators and save
                </p>
                <p className="text-yellow2 text-5xl laptop:text-4xl font-jubilat font-semibold my-4 laptop:my-2 phoneMd:w-3/4 tabletWide:w-auto laptopSm:w-3/4">
                  +900,000
                </p>
                <p className='text-white text-xs mx-auto w-3/4 '>
                  Barrels of Oil annually.
                </p>
              </div>
            </div>

            <div className="p-4 laptop:px-1">
              <div className="h-80 laptop:h-350px bg-darkblue rounded-2xl flex flex-col items-center justify-start">
                <p className="text-center text-yellow2 text-lg font-semibold mt-4">
                  RESIDENTIAL POWERBILL
                  <br />
                  BASED ON $100/BBL FUEL
                  <span className='text-white text-sm font-normal block'>Based on 1000kwh residential consumption</span>
                </p>
                <div className='w-full border-b border-2 border-white my-4 laptop:mt-2 laptop:mb-4' />
                <div className='flex flex-row w-full items-center relative'>
                  <div
                    className="w-2/5 phoneLg:w-1/5 tabletWide:w-2/5 laptop:w-20 h-32 absolute top-4 left-4"
                    style={{
                      backgroundImage: `url(${Images.arrowDown})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  />
                  <div className='w-full'>
                    <p className='ml-32 laptop:ml-28 text-white text-xs'>
                      January 2025
                    </p>
                    <p className='ml-32 laptop:ml-28 text-yellow2'>
                      $359
                    </p>
                    <div className='w-full border-b border border-white my-2 w-full' />
                    <p className='ml-32 laptop:ml-28 text-white text-xs'>
                      February 2025
                    </p>
                    <p className='ml-32 laptop:ml-28 text-yellow2'>
                      $306
                    </p>
                    <div className='w-full border-b border border-white my-2 w-full' />
                    <p className='ml-32 laptop:ml-28 text-white text-xs'>
                      September 2025
                    </p>
                    <p className='ml-32 laptop:ml-28 text-yellow2'>
                      $264
                      <span className='ml-1 text-xs'>(proposed)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FAQs Section */}
          <div className="flex items-center mt-8">
            <h1 className="font-normal font-rubik text-blue phone:text-lg tablet:text-xl laptop:text-2xl desktop:text-2xl phone:mt-0">
              FAQ's
            </h1>
            <FaPlus
              className='text-blue'
              // eslint-disable-next-line no-unused-vars
              onClick={(_event): MouseEventHandler<SVGElement> | void =>
                setShowFaqsContent((prevState) => !prevState)
              }
              style={{
                ...(showFaqsContent ? closeAnimation : openAnimation),
                marginLeft: '15px',
                cursor: 'pointer',
              }}
            />
          </div>
          {showFaqsContent ? (
            <>
              <Details>
                <div className='w-10 h-10' />
                <p className='font-semibold'>
                  General Questions
                </p>

                <p className='font-semibold my-2'>
                  1. What is GPA requesting, and how does it affect my bill?
                </p>
                <p>
                  GPA has been working diligently to lower power bills for its customers. As part of this effort, GPA is petitioning the Guam Public Utilities Commission (PUC) for a slight base rate increase to cover the costs of the Ukudu Power Plant while simultaneously petitioning PUC during the next LEAC period to reduce the Fuel Recovery Charge (LEAC). The LEAC, if approved by the PUC would mean a reduction to approximately 13-14 cents per kilowatt-hour (kWh) resulting in a additional savings for residential customers using about 1000 kilowatt-hours of power.
                  <br />
                  The goal is to provide reliable and affordable power on a sustained basis, and this adjustment will bring much-needed relief to customers by reducing fuel costs and stabilizing long-term energy pricing.
                </p>

                <p className='font-semibold my-2'>
                  2. How is the base rate different from the Fuel Recovery Charge (LEAC)?
                </p>
                <p>
                  The base rate funds GPA’s fixed costs, including operations, maintenance, infrastructure improvements, and debt service (similar to a mortgage payment). As a result of this infrastructure investment, GPA would have to adjust the base rate portion of your power bill which is fixed to pay for the new Ukudu Power Plant and can only be changed by the PUC.
                  <br />
                  The Fuel Recovery Charge (LEAC) fluctuates based on global fuel prices and is reviewed every six months. With the transition to Ukudu and the closure of Cabras 1 & 2, GPA expects a significant reduction in fuel consumption, leading to lower LEAC costs. The Ukudu Power Plant is 37% more fuel efficient than Cabras 1 and 2 and will reduce fuel imports by over 900,000 barrels per year.
                </p>

                <p className='font-semibold my-2'>
                  3. Why is GPA adjusting both the base rate and LEAC at the same time?
                </p>
                <p>
                  GPA has been committed to making electricity more efficient and affordable for its customers on a sustained basis. By implementing both changes simultaneously, GPA is reducing the overall cost of power for customers. While the base rate increase is necessary to cover the costs (similar to mortgage payments) of the Ukudu Power Plant, the reduction in the LEAC charge due to lower fuel consumption by the Ukudu Plant helps reduce the overall financial impact on customers.
                  <br />
                  By timing the rate adjustments to meet the first payment obligation (similar to a mortgage payment) for the Ukudu Plant, the lower fuel costs will immediately offset the base rate adjustment, resulting in lower monthly bills for customers effective September 1, 2025. At GPA, our goal is to reduce overall costs and lower power bills to our customers.
                </p>

                <p className='font-semibold my-2'>
                  4. When will these changes take effect?
                </p>
                <p>
                  Pending PUC approval, the new rates would take effect on September 1, 2025. The proposed LEAC reduction is also being requested by GPA to be implemented at the same to ensure customers see immediate savings on their bills.
                </p>

                <p className='font-semibold my-2'>
                  5. How does the Ukudu Power Plant contribute to reducing fuel costs?
                </p>
                <p>
                  The Ukudu Power Plant is a highly efficient combined-cycle plant that significantly reduces the amount of fuel required to generate electricity. By replacing older, inefficient generators and transitioning to highly fuel-efficient units and cleaner energy, GPA will lower fuel imports per year, resulting in reduced LEAC charges. The Ukudu Power Plant is 37% more fuel efficient than Cabras 1 and 2 and 22% more efficient than Piti 8 and 9. This efficiency will help reduce fuel imports by over 900,000 barrels per annum or 39 million gallons. The fuel efficiency of the Ukudu Power Plant will more than offset the base rate adjustment and provide net savings to our customers.
                  <br />
                  The current Energy Conversion Agreement (ECA) with GPA’s partner Guam Ukudu Power LLC, allows for the Ukudu Power Plant to be turned over to GPA in 25 years once the contract is completed.
                </p>

                <div className='w-10 h-10' />
                <p className='font-semibold'>
                  Customer Impact
                </p>

                <p className='font-semibold my-2'>
                  6. When will the new rates go into effect?
                </p>
                <p>
                  Pending PUC approval, the new rates would be expected to take effect September 1, 2025.
                  <br />
                  The proposed rates will be published in newspapers, mailed to individual ratepayers, and posted on GPA’s webpage. The Ratepayer’s Bill of Rights (Public Law 26-23) requires GPA to publish in a newspaper of general circulation, notice of the proposed rates and justification at least three (3) months before petitioning the PUC. Every current ratepayer will be mailed a notice of the proposed rate changes one (1) month before petitioning the PUC. The new base rate will pay for the Ukudu Plant, similar to a mortgage payment. The new LEAC adjustment will account for the start of a total reduction in fuel imports by over 900,000 barrels per year according to GPA’s most recent calculations. The fuel efficiency of the Ukudu Power Plant will more than offset the base rate adjustment and provide net savings to our customers.
                </p>

                <p className='font-semibold my-2'>
                  7. What will my new monthly bill look like?
                </p>
                <p>
                  The actual impact on your bill depends on your energy consumption and rate class. In addition to the published notices in the newspapers, GPA will provide detailed billing illustrations for the different rate schedules on its website. A rate calculator will be provided on GPA’s website to help customers understand how these changes affect them. While the base rate increases, the LEAC reduction is designed to reduce the overall cost for customers. These adjustments will reduce the overall power bills and provide net savings to our customers.
                </p>

                <p className='font-semibold my-2'>
                  8. How will customers benefit from the LEAC reduction?
                </p>
                <p>
                  The current LEAC represents approximately 70% of customers' power bills. The proposed reduction to 13-14 cents means customers will pay less on their overall energy costs.
                </p>

                <p className='font-semibold my-2'>
                  9. Will all customers see the same changes in their bills?
                </p>
                <p>
                  The impact varies by customer class and consumption levels. Upon authorization by the CCU, all proposed rates will be published in the newspapers and will be available online as GPA will conduct outreach to its customers during the petition period.
                </p>

                <p className='font-semibold my-2'>
                  10. Are there any programs available to help customers reduce power consumption and manage their bills?
                </p>
                <p>
                  Yes. Customers can explore tools such as MyEnergyGuam and MyEnergyXpert to monitor and optimize energy usage. GPA’s Energy Sense Program provides cash rebates for its customers when they invest in energy-efficient appliances (air conditioners, washers and dryers) to help reduce demand and consumption. These platforms and programs provide insights into consumption patterns and offer recommendations for improving efficiency and reducing costs. Customers can visit <a className="text-blue" href="https://guampowerauthority.com/">www.guampowerauthority.com</a> for more information.
                  <br />
                  Additionally, GPA collaborates with the Guam Energy Office on its Weatherization Assistance Program and the Low Income Home Energy Assistance Program (LIHEAP). These programs assist income-eligible applicants may be able to improve their home energy efficiency and receive payment assistance for power bills.
                </p>

                <div className='w-10 h-10' />
                <p className='font-semibold'>
                  Reasons & Justification
                </p>

                <p className='font-semibold my-2'>
                  11. How does GPA determine when a base rate adjustment is necessary?
                </p>
                <p>
                  GPA assesses financial obligations, power generation needs, and infrastructure investments. Over the years, GPA has implemented operational efficiencies, leveraging Smart Grid and AI technology to optimize grid operations and reduce unnecessary costs. These advancements have helped minimize expenses related to labor costs. By implementing cost-saving measures and efficiency improvements, GPA has worked to reduce the overall impact of the base rate adjustment for Ukudu. Additionally, the base rate has not increased since 2013, making this adjustment necessary to account for inflation, capital improvement, to cover the costs (similar to a mortgage payment) of the Ukudu Power Plant, and the transition to newer, more efficient energy infrastructure.
                </p>

                <p className='font-semibold my-2'>
                  12. Why is a base rate increase needed now?
                </p>
                <p>
                  The Ukudu Power Plant's financial obligations (similar to mortgage payments) begin in September 2025, requiring GPA to ensure stable funding. Additionally, GPA must comply with bond covenants to maintain financial stability and sufficient revenue to meet debt obligations. Delaying or denying this adjustment could impact GPA’s ability to provide reliable service. <b>Without Ukudu</b>, the savings on fuel could not be extended to customers. <b>Without Ukudu</b>, GPA will not be able to comply with USEPA/GPA consent decree potentially resulting in millions in penalties. <b>Without Ukudu</b>, Guam will continue to be susceptible to volatile oil prices.
                </p>

                <p className='font-semibold my-2'>
                  13. What operational costs are covered by the base rate adjustment?
                </p>
                <p>
                  The proposed base rate adjustment is tied to the payments for the Ukudu Power Plant once it is commissioned and the necessary capital improvement projects to provide for continued operational efficiency and reliability while being financially stable to meet debt service coverage.
                </p>

                <p className='font-semibold my-2'>
                  14. How does this adjustment support Guam’s energy future?
                </p>
                <p>
                  The combination of the Ukudu Power Plant and increased renewable energy integration aligns with GPA’s Clean Energy Master Plan. By reducing reliance on expensive fuel and modernizing infrastructure, GPA is ensuring more sustainable, affordable, and reliable power for Guam in the long term by reducing our dependency on imported fuel.
                </p>

                <div className='w-10 h-10' />
                <p className='font-semibold'>
                  Transparency & Accountability
                </p>

                <p className='font-semibold my-2'>
                  15. How can customers review and provide feedback on these changes?
                </p>
                <p>
                  Customers can review GPA’s full petition and supporting documents on its website and through the Guam Public Utilities Commission. Public hearings will be held where customers can ask questions, voice concerns, and provide input. Customers may also submit feedback via email or GPA’s website (<a className="text-blue" href="https://guampowerauthority.com/">www.guampowerauthority.com</a>).
                </p>

                <div className='w-10 h-10' />
                <p>This FAQ is designed to help customers understand how GPA’s base rate adjustment and LEAC reduction work together to minimize financial impact. For more details, visit <a className="text-blue" href="https://guampowerauthority.com/">www.guampowerauthority.com</a> or contact GPA directly at 671 648-3225 or 671 648-3145.</p>
                <div className='w-10 h-10' />
                <p>Updated as of 2/21/25</p>
              </Details>
            </>
          ) : null}

          {/* Rate Adjustments Section */}
          <div className="flex items-center mt-8">
            <h1 className="font-normal font-rubik text-blue phone:text-lg tablet:text-xl laptop:text-2xl desktop:text-2xl phone:mt-0">
              Proposed Rate Adjustments
            </h1>
            <FaPlus
              className='text-blue'
              // eslint-disable-next-line no-unused-vars
              onClick={(_event): MouseEventHandler<SVGElement> | void =>
                setShowRateAdjustmentsContent((prevState) => !prevState)
              }
              style={{
                ...(showRateAdjustmentsContent ? closeAnimation : openAnimation),
                marginLeft: '15px',
                cursor: 'pointer',
              }}
            />
          </div>
          {showRateAdjustmentsContent ? (
            <>
              <Details>
                No contents to show as of the moment
              </Details>
            </>
          ) : null}

          {/* Billing Samples Section */}
          <div className="flex items-center mt-8">
            <h1 className="font-normal font-rubik text-blue phone:text-lg tablet:text-xl laptop:text-2xl desktop:text-2xl phone:mt-0">
              Billing Samples
            </h1>
            <FaPlus
              className='text-blue'
              // eslint-disable-next-line no-unused-vars
              onClick={(_event): MouseEventHandler<SVGElement> | void =>
                setBillingSamplesContent((prevState) => !prevState)
              }
              style={{
                ...(showBillingSamplesContent ? closeAnimation : openAnimation),
                marginLeft: '15px',
                cursor: 'pointer',
              }}
            />
          </div>
          {showBillingSamplesContent ? (
            <>
              <Details>
                <UnorderedList className='my-4'>
                    <List
                      className="text-orange mb-4"
                      key="Appendix-A-r2-2-23-05"
                    >
                      <a
                        href="/assets/Appendix-A-r2-2-23-05.pdf"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Appendix A r2 2-23-05.pdf
                      </a>
                    </List>
                </UnorderedList>
              </Details>
            </>
          ) : null}

          {/* Petitions Section */}
          <div className="flex items-center mt-8">
            <h1 className="font-normal font-rubik text-blue phone:text-lg tablet:text-xl laptop:text-2xl desktop:text-2xl phone:mt-0">
              Petitions
            </h1>
            <FaPlus
              className='text-blue'
              // eslint-disable-next-line no-unused-vars
              onClick={(_event): MouseEventHandler<SVGElement> | void =>
                setShowPetitionsContent((prevState) => !prevState)
              }
              style={{
                ...(showPetitionsContent ? closeAnimation : openAnimation),
                marginLeft: '15px',
                cursor: 'pointer',
              }}
            />
          </div>
          {showPetitionsContent ? (
            <>
              <Details>
                No contents to show as of the moment
              </Details>
            </>
          ) : null}

          {/* Reports Section */}
          <div className="flex items-center mt-8">
            <h1 className="font-normal font-rubik text-blue phone:text-lg tablet:text-xl laptop:text-2xl desktop:text-2xl phone:mt-0">
              Workforce Reports
            </h1>
            <FaPlus
              className='text-blue'
              // eslint-disable-next-line no-unused-vars
              onClick={(_event): MouseEventHandler<SVGElement> | void =>
                setShowReportsContentset((prevState) => !prevState)
              }
              style={{
                ...(showReportsContent ? closeAnimation : openAnimation),
                marginLeft: '15px',
                cursor: 'pointer',
              }}
            />
          </div>
          {showReportsContent ? (
            <>
              <Details>
                No contents to show as of the moment
              </Details>
            </>
          ) : null}
        </div>

        {/* Work Progress Section */}
        <div className="p-4 w-full phoneLg:p-12 tabletWide:p-8 laptopSm:p-12 tabletWide:w-1/4 laptopSm:w-1/5 laptop:w-1/4 bg-yellow2 tabletWide:flex tabletWide:flex-col tabletWide:justify-start tabletWide:space-y-12 laptopSm:space-y-4">
          <p className="my-4 text-xl tablet:text-2xl laptop:text-3xl font-bold">
            Your Voice Matters
          </p>

          <p className="mb-8 text-lg">
            We understand that these changes
            may bring questions or concerns - and
            we want to hear from you.
          </p>

          <a href="mailto:feedback@gpagwa.com">
            <button
              className="w-full h-20 bg-orange flex flex-row justify-center items-center cursor-pointer hover:opacity-90"
              type="button"
            >
              <p className="font-rubik text-2xl text-white">Email Questions</p>
            </button>
          </a>


          <p className="my-4 text-xl tablet:text-2xl laptop:text-3xl font-bold">
            News Release
          </p>

          <Details className="flex flex-col">
            {/* {sop?.map((sopItem) => {
                        return (
                          <a
                            className="text-orange"
                            href={`${API}${sopItem.attributes.File.data.attributes.url}`}
                            key={sopItem.id}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {sopItem.attributes.Title}
                          </a>
                        );
                      })} */}
            <UnorderedList>
              {reports?.map((value) => {
                if (value.attributes.File.data.attributes.url !== '') {
                  return (
                    <List
                      className="text-orange mb-4"
                      key={value.attributes.File.data.attributes.url}
                    >
                      <a
                        href={`${API}${value.attributes.File.data.attributes.url}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {value.attributes.Title}
                      </a>
                    </List>
                  );
                }

                return (
                  <>
                    {/* {file
                                      .filter((fileData) => fileData.attributes.AnchorText === value.anchor)
                                      .map((fileData) => {
                                        return (
                                          <>
                                            <List className="text-orange" key={value.anchor}>
                                              <a
                                                className="text-orange"
                                                href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                                rel="noreferrer"
                                                target="_blank"
                                              >
                                                {value.anchor}
                                              </a>
                                            </List>
                                          </>
                                        );
                                      })} */}
                  </>
                );
              })}
            </UnorderedList>
            {
              reports === null
                ? <p className="">
                  No data to be displayed
                </p>
                : <></>
            }
          </Details>

          {/* Extra Component */}
          <div className="hidden h-96 tabletWide:flex laptop:hidden" />
        </div>
      </section>
    </>
  );
};
