export const AnticipatedProjectsOverview = {
  pageTitle: `Strategic Planning and Operations Research`,
  title: `Anticipated Projects`,
  description: `Fiber Project : GPA plans to install fiber in its existing underground conduits except for San Vitores to GMH (Note: Tanguisson to Harmon Fiber Line is already existing).`,
  anchor: `Underground Location map pdf 420kB`,
  link: ``,
  groupTitle: `ARRA Grant Funded Projects`,
};

export const SmartGridProgram = {
  title: `Smart Grid Program`,
  table: [
    {
      title: `PROGRAM SCOPE OVERVIEW`,
      description: `The GPA Connected Grid Project is a comprehensive, integrated crosscutting program including communications systems, advanced metering infrastructure, distribution automation projects, advanced information and control systems, outage and workforce management systems, energy demand management and control, enhanced security systems, and distributed energy resource and storage projects. These projects cut across the entire GPA enterprise. Additionally, the program includes support for homeland security issues including cyber and physical security and protection of critical infrastructure, life and property.`,
      tableTitle: `ARRA GRANT AWARD OVERVIEW`,
      table1: [
        {
          heading: `Award Number`,
          data: `DE-OE0000252`,
        },
        {
          heading: `Total Award Amount`,
          data: `$16,603,507`,
        },
        {
          heading: `Award Date`,
          data: `03/11/2010`,
        },
        {
          heading: `Project Status`,
          data: `Less Than 50% Completed`,
        },
        {
          heading: `Jobs Reported`,
          data: `4.43`,
        },
        {
          heading: `Project Location`,
          data: `GU`,
        },
      ],
      table2: [
        {
          heading: `Funding Agency`,
          data: `Department of Energy`,
        },
        {
          heading: `Project Location - City`,
          data: `Hagatna`,
        },
        {
          heading: `Project Location - State`,
          data: `GU`,
        },
        {
          heading: `Project Location - Zip`,
          data: `96932-2977`,
        },
        {
          heading: `Congressional District`,
          data: `98`,
        },
      ],
      anchor: `RECOVERY.GOV`,
      link: `https://www.recovery.gov/Transparency/RecipientReportedData/pages/RecipientProjectSummary508.aspx?AwardIdSur=93919`,
    },
  ],
  contents: [
    {
      title: `Acknowledgment`,
      description: `This material is based upon work supported by the Department of Energy under Award Number DE-OE0000252.`,
    },
    {
      title: `Disclaimer`,
      description: `Reports and materials on this web site were prepared as an account of work sponsored by an agency of the United States Government. Neither the United States Government nor any agency thereof, nor any of their employees, makes any warranty, express or implied, or assumes any legal liability or responsibility for the accuracy, completeness, or usefulness of any information, apparatus, product, or process disclosed, or represents that its use would not infringe privately owned rights. Reference herein to any specific commercial product, process, or service by trade name, trademark, manufacturer, or otherwise does not necessarily constitute or imply its endorsement, recommendation, or favoring by the United States Government or any agency thereof. The views and opinions of authors expressed herein do not necessarily state or reflect those of the United States Government or any agency thereof.`,
    },
  ],
};

export const EneryEfficientStreetlightProject = {
  title: `Energy Efficient Streetlight Project`,
  subTitle: `PROGRAM SCOPE OVERVIEW`,
  p1: `The Energy Efficient Street Lighting Project will install 520 induction street lights in the Tumon Hotel District and about 1400 energy efficient street lights elsewhere on Guam. This project has drawn from the collective efforts of the Governor's Office, the Guam Energy Office (GEO), the Guam Visitors Bureau (GVB), The Department of Public Works (DPW), the Guam Legislature especially Senator Tom C. Ada, the Guam Environmental Protection Agency (GEPA), and the Guam Power Authority (GPA).`,
  p2: `The Energy Efficient Street Lighting Project receives its $2.5 million grant funding from the Energy Efficiency and Conservation Block Grant (EECBG) program through the Guam Energy Office (GEO).`,
  anchor: `Guam Energy Office Grants Page`,
  link: `https://www.guamenergy.com/about/grants/`,
  emptyTitle: `Building Retrofit Project`,
};