import React, { Fragment, useEffect, useState } from 'react';
import {
  Content as ContentContainer,
  Details,
  H1,
  H2,
  List,
  Table,
  TableCell as TD,
  UnorderedList,
} from '@core';
import { HeaderGradient } from '@components';
import { getLEACTableData } from '@services';
import { LEACDATA } from '@types';
import { Tab } from '@headlessui/react';
import { LEACCosts } from '@src/content';
import { CORPORATEFINANCIALS } from '@src/types/CorporateFinancials';
import { API } from '@src/config';
import { getFinancialFuelPurchases } from '@src/services/FinancialsFuelPurchases';
import { getPucFinancialsLeac } from '@src/services/FinancialsPucLeac';

export const LEAC = () => {
  const [tableData, setTableData] = useState<LEACDATA[]>([]);
  const [fuelPurchases, setFuelPurchases] = useState<CORPORATEFINANCIALS[]>([]);
  const [pucLeacOrders, setPucLeacOrders] = useState<any[]>([]);

  const getData = async () => {
    const tableDatas = await getLEACTableData();
    const fuelPurchasesData = await getFinancialFuelPurchases();
    const pucLeacOrdersData = await getPucFinancialsLeac();

    console.log(pucLeacOrdersData.data, 'leac data');

    setTableData(tableDatas.data);
    setFuelPurchases(fuelPurchasesData.data);
    setPucLeacOrders(pucLeacOrdersData?.data);
  };

  const clickItem = (link?: string, file?: any) => {
    if (file != null) {
      window.open(`${API}${file}`);
    } else {
      window.open(`${link}`);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Levelized Energy Adjustment Clause (LEAC)"
      />
      <ContentContainer className="">
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <Table className="my-10">
                <tr>
                  <TD rowType="heading">Delivery Classification per kWh</TD>
                  <TD rowType="heading">Effective 06/01/23</TD>
                  <TD rowType="heading">Effective 02/01/24</TD>
                  <TD rowType="heading">Effective 08/01/24</TD>
                  <TD rowType="heading">Effective 02/01/25</TD>
                </tr>
                {tableData.map((data) => {
                  return (
                    <>
                      <tr>
                        <TD>{data.attributes.Factor}</TD>
                        <TD>{data.attributes.August2021}</TD>
                        <TD>{data.attributes.October2021}</TD>
                        <TD>{data.attributes.December2021}</TD>
                        <TD>{data.attributes.April2022}</TD>
                      </tr>
                    </>
                  );
                })}
                {/* {LEACRates.table.datas.map((data, index) => (
                  <tr key={index}>
                    {data.map((cell) => (
                      <TD key={cell}>{cell}</TD>
                    ))}
                  </tr>
                ))} */}
              </Table>
              <div className="mr-20 w-11/12">
                {/* <UnorderedList>
                  <div className="grid gap-x-10 laptop:grid-flow-row phone:grid-flow-row">
                    {LEACRates.ol.map((value) => {
                      return (
                        <>
                          {file
                            .filter((fileData) => fileData.attributes.AnchorText === value.text)
                            .map((fileData) => {
                              return (
                                <>
                                  <List className="text-orange">
                                    <a
                                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {fileData.attributes.AnchorText}
                                    </a>
                                  </List>
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                  </div>
                </UnorderedList> */}
              </div>
            </section>
            <section className="mb-20 tablet:mb-10">
              <H1 className="">FUELS</H1>
              <Tab.Group>
                <Tab.List className="flex border-b justify-left tab-list-style">
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <div
                        className={`${selected ? 'bg-blue text-white' : 'bg-lightgray text-blue'
                          } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                      >
                        LEAC COST
                      </div>
                    )}
                  </Tab>
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <div
                        className={`${selected ? 'bg-blue text-white' : 'bg-lightgray text-blue'
                          }  py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                      >
                        FUEL PURCHASES
                      </div>
                    )}
                  </Tab>
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <div
                        className={`${selected ? 'bg-blue text-white' : 'bg-lightgray text-blue'
                          }  py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                      >
                        PUC LEAC Orders
                      </div>
                    )}
                  </Tab>
                </Tab.List>
                <Tab.Panels className="">
                  <Tab.Panel>
                    <div className="my-10">
                      <H2 className="mt-5 mb-2 text-blue">{LEACCosts.title}</H2>
                      <Details>{LEACCosts.p1}</Details>
                      <UnorderedList>
                        {LEACCosts.ul.map((value) => {
                          return (
                            <>
                              <List>{value}</List>
                            </>
                          );
                        })}
                      </UnorderedList>
                      <Details>{LEACCosts.p2}</Details>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="my-10">
                      <H2 className="mb-5 text-blue">P.L. 30-29 Disclosure - Fuel Purchases</H2>
                      <UnorderedList>
                        {fuelPurchases.map((value) => {
                          return (
                            <List
                              className="whitespace-nowrap text-orange"
                              key={value.attributes.createdAt}
                            >
                              {value.attributes.Anchor != null ? (
                                <button
                                  className="text-left"
                                  onClick={() =>
                                    clickItem(
                                      value.attributes.Link,
                                      value.attributes.File?.data != null
                                        ? value.attributes.File.data.attributes.url
                                        : value.attributes.File.data,
                                    )
                                  }
                                  type="button"
                                >
                                  {value.attributes.Anchor.length > 22
                                    ? `${value.attributes.Anchor.substring(0, 22)}...`
                                    : value.attributes.Anchor}
                                </button>
                              ) : null}
                            </List>
                          );
                        })}
                      </UnorderedList>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="my-10">
                      <H2 className="mt-5 mb-2 text-blue">PUC LEAC Orders</H2>

                      <UnorderedList>
                        {pucLeacOrders?.map((value: any) => {
                          return (
                            <List className="text-orange" key={value?.attributes?.Anchor}>
                              <a
                                href={`${API}${value.attributes.File?.data.attributes.url}`}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {value?.attributes?.Anchor}
                              </a>
                            </List>
                          );
                        })}
                      </UnorderedList>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
