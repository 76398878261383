import { API } from '@config';
// import { SOPDATA } from '@src/types/SOPindex';

// export const searchGpa = async (searchString: string | null, page: number, pageSize: number) => {
export const getSOPData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let data;

  try {
    // const response = await fetch(
    //   `${API}/api/search-results?filters[$or][0][title][$containsi]=${searchString}&filters[$or][1][description][$containsi]=${searchString}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
    //   options,
    // );
    const response = await fetch(`${API}/api/standard-operating-procedures?populate=File&sort=Title`, options);
    data = await response.json();

    return data;
  } catch (err) {
    // console.error(err);
  }

  return data;
};
