import { InfoVidImages } from '@src/core';

export const FuturePlanning = {
  pageTitle: `Future Planning`,
  contents: [
    {
      title: `GPA is working hard to insure a bright future`,
      img: InfoVidImages.Forum,
      imgAlt: `Leading the Next Generation, GPA Integrated Resource Plan Forum`,
      anchor: `GPA Integrated Resource Plan Forum Information (April 14, 2014)`,
      link: `https://www.gpairpforum.com/`,
    },
  ],
  sessions: [
    {
      title: `Session Videos GPA Integrated Resource Plan Forum Information (April 14, 2014)`,
      img: InfoVidImages.Montage,
      imgAlt: `GPA Integrated Resource Plan Forum Montage`,
    },
  ],
};

export const ForumVideos = {
  contents: [
    {
      title: `01 GPA Integrated Resource Plan Forum: Welcome`,
      duration: `Video 11:19`,
      description: `Welcoming Remarks by Consolidated Commission on Utilities Chairman Simon Sanchez`,
      link: `https://youtu.be/IguPi1vjcYw`,
    },
    {
      title: `02 GPA Integrated Resource Plan Forum: Governor’s Remarks`,
      duration: `Video 9:03`,
      description: `“A Call to Stabilize Electricity Costs for Our People” The Honorable Eddie Baza Calvo, Governor of Guam`,
      link: `https://youtu.be/APgkNssWoNw`,
    },
    {
      title: `03 GPA Integrated Resource Plan Forum: Opening Address/Overview`,
      duration: `Video 30:01`,
      description: `Welcoming Remarks by Consolidated Commission on Utilities Chairman Simon Sanchez`,
      link: `https://youtu.be/7b1bxld18o4`,
    },
    {
      title: `04 GPA Integrated Resource Plan Forum: Session 1 Renewables`,
      duration: `Video 1:23:07`,
      description: `Welcoming Remarks by Consolidated Commission on Utilities Chairman Simon Sanchez`,
      link: `https://youtu.be/S3_dosxZ8ns`,
    },
    {
      title: `05 GPA Integrated Resource Plan Forum: Energy Storage`,
      duration: `Video 43:59`,
      description: `David W. Burlingame, P.E. Principal, Electric Power Systems, Inc.`,
      link: `https://youtu.be/95XzBJ9hYBQ`,
    },
    {
      title: `06 Keynote Speech: PUC Role`,
      duration: `Video 16:35`,
      description: `Public Utilities Commission Role in the GPA Integrated Resource Plan, Frederick Horecky, Administrative Law Judge/Legal Counsel, Public Utilities Commission.`,
      link: `https://youtu.be/EkrUnAx9oPE`,
    },
    {
      title: `07 Keynote Speech: Legislature Role`,
      duration: `Video 19:26`,
      description: `Guam Legislature Role in the GPA Integrated Resource Plan, The Honorable Thomas C. Ada, Senator, 32nd Guam Legislature, Assistant. Majority Leader I.`,
      link: `https://youtu.be/9n07n_SfR8I`,
    },
    {
      title: `08 GPA Integrated Resource Plan Forum: Session 2 Fuel and New Power Generation`,
      duration: `Video 1:47:37`,
      description: `The Future of LNG Supply in the Western Pacific, Biliana Pehlivanova, Head of Natural Gas, Global LNG & Power Research, Barclays. Potential LNG Strategies for Guam, Douglas E. Cotton, Senior Project Manager, HDR. GPA’s Power Generation Strategy, John J. Cruz, Jr., P.E., GPA Strategic Planning & Operations Research Division Manger.`,
      link: `https://youtu.be/asL1soPVcdo`,
    },
    {
      title: `09 GPA Integrated Resource Plan Forum: Financing for LNG `,
      duration: `Video 9:03`,
      description: `Sean Keatts, Director, Barclays; Hiran Cantu, Director, Barclays; and John Miller, Citi`,
      link: `https://youtu.be/3z3b4ywSlug`,
    },
    {
      title: `10 GPA Integrated Resource Plan Forum: PM Potential Contracts & Legal Structures: `,
      duration: `Video 48:38`,
      description: `Daniel R. Rogers, Partner, King & Spalding, LLPk`,
      link: `https://youtu.be/2GlQRCWnVQk`,
    },
    {
      title: `11 GPA Integrated Resource Plan Forum: Closing Remarks`,
      duration: `Video 3:37`,
      description: `Joaquin C. Flores, P.E., General Manager Guam Power Authority`,
      link: `https://youtu.be/-xLiRwchQ-U`,
    },
  ],
};

export const AboutIRP = {
  anchor: `About the Integrated Resource Plan (IRP)`,
  link: `https://www.gpairpforum.com/`,
  p1: `In recent years, GPA has greatly improved the capacity, reliability and efficiency of the island-wide power generating system. At the same time the cost of the oil used for making electricity has increased as have concerns over the climate crisis. In addition to these world issues, Guam faces dramatic local growth because of the announced military build up and the steady demands of our growing standard of living.`,
  p2: `GPA is working hard to insure a bright future for the people of Guam in several areas.`,
  img: InfoVidImages.Turbine,
  ul: [
    `Emphasizing the professional training of the current workforce has resulted in greater efficiency in the islandwide power generating and delivery system, that is in place today.`,
    `In addition, an emphasis on employee training and safety, capital improvements, and increased maintenance haveactually saved money for ratepayers.`,
    `Providing career opportunities to our youth through apprenticeship programs, scholarships and internship experiences ensures that GPA will continue to have well-trained employees in the future. GPA partners with accredited local institutions of higher learning and off island technical and educational institutions in licensed programs. Our people are our most important investment.`,
    `GPA is leading the way in exploring alternative ways of producing power for Guam that are both economical and environmentally responsible. The development of the Guam IRP will be a primary key for meeting our needs for electricity in our changing world.`,
  ],
};