import React, { useEffect, useState } from 'react';
import { H1, Details, Content as ContentContainer, UnorderedList, List, Images } from '@core';

import { getCybersecurityFAQ } from '@src/services';
import { SOPDATA } from '@src/types/SOPindex';
import { API } from '@src/config';

export const Cybersecurity = () => {
  const [reports, setReports] = useState<SOPDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getCybersecurityFAQ();

    setReports(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <div
        className="relative mt-20 desktop:mt-28 w-full tablet:hidden"
        style={{
          height: '300px',
          backgroundImage: `url(${Images.cybersecurityMobile})`,
          backgroundPosition: 'left',
          backgroundSize: 'cover',
        }}
      />
      <div
        className="relative mt-20 desktop:mt-28 w-full hidden tablet:block"
        style={{
          height: '450px',
          backgroundImage: `url(${Images.cybersecurity})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="flex-col items-center justify-end w-full h-full hidden tablet:flex">
          <div className="flex flex-col justify-center items-center ml-auto w-1/2 h-full laptop:p-14 phone:p-8 text-center">
            <h1 className="mb-1 font-bold text-darkblue font-jubilat tablet:text-4xl tabletWide:text-5xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5">
              GPA
            </h1>
            <h1 className="mb-1 font-bold text-darkblue font-jubilat tablet:text-4xl tabletWide:text-5xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5">
              Cybersecurity:
            </h1>
            <h1 className="mb-1 font-bold text-darkblue font-jubilat tablet:text-4xl tabletWide:text-5xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5">
              Protecting
            </h1>
            <h1 className="mb-1 font-bold text-darkblue font-jubilat tablet:text-4xl tabletWide:text-5xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5">
              Guam's Energy
            </h1>
            <h1 className="mb-1 font-bold text-darkblue font-jubilat tablet:text-4xl tabletWide:text-5xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5">
              Future
            </h1>
          </div>
        </div>
      </div>
      <ContentContainer>
              <section id="contact-us">
                  <H1 className='font-jubilat font-bold'>GPA Cybersecurity: Protecting Guam’s Energy Future</H1>
                  <Details>
                      Guam Power Authority (GPA) has been at the forefront of cybersecurity, ensuring the
                      security and resilience of Guam’s energy infrastructure. Since implementing its
                      DOE-approved cybersecurity plan in 2015, GPA has remained proactive in adapting to
                      increasingly sophisticated threats in cooperation with federal authorities. Cybersecurity is a
                      critical component of GPA’s mission to provide efficient, reliable, and secure power to Guam’s
                      residents and partners.
                      <br />
                      <br />
                      GPA’s cybersecurity program aligns with national standards, adopting the NIST
                      Cybersecurity Framework, NIST Special Publication (SP) 800 series guidelines, applicable
                      NERC CIP standards, and more. It encompasses both information technology (IT) and
                      operational technology (OT) systems, with core cybersecurity personnel holding specialized
                      certifications such as Global Industrial Cyber Security Professional (GICSP).
                      <br />
                      <br />
                      Ongoing real-time monitoring and partnerships with cybersecurity entities reinforce GPA’s
                      readiness and resilience. In addition to its cybersecurity investments, GPA has developed an
                      independent fiber-optic network to reduce vulnerabilities and enhance control over critical
                      systems. The Authority continues to integrate advanced networking solutions to strengthen
                      its operational capabilities and safeguard critical infrastructure against evolving cyber
                      threats.
                  </Details>
              </section>
              <section id="contact-us">
                  <H1 className='font-jubilat font-bold'>GPA Cybersecurity Efforts Frequently Asked Questions (FAQ)</H1>
                  <Details>
                      {/* ------------------------------------------- */}
                      <p className='font-bold'>1. What is the current state of GPA's cybersecurity readiness, and how does it compare
                          to other similar agencies or power companies?</p>
                      <br />
                      GPA is at the forefront of cybersecurity readiness, protecting the island’s energy
                      infrastructure through a robust Cybersecurity Plan (CSP) aligned with the National Institute
                      of Standards and Technology (NIST) Cybersecurity Framework and applicable North
                      American Electric Reliability Corporation (NERC) CIPs. First approved by the U.S. Department
                      of Energy (DOE) in 2015 under the Smart Grid Initiative Grant, this plan has been continuously
                      enhanced to address emerging threats.
                      <br />
                      <br />
                      Since 2019, regular audits by cybersecurity special entities and other federal agencies have
                      ensured compliance with national standards. GPA’s multi-layered approach integrates
                      advanced technology, ongoing staff training, and stringent vendor requirements, including
                      compliance with Trade Agreements Act provisions.
                      <br />
                      <br />
                      Cybersecurity is a core element of GPA’s mission to provide reliable power. Collaborations
                      with national laboratories, such as NREL, PNNL, and INL, further strengthen its efforts.
                      Through proactive planning and partnerships, GPA remains a leader in securing Guam’s
                      energy infrastructure, ensuring a safe and sustainable future for the island.
                      <br />
                      <br />
                      {/* ------------------------------------------- */}
                      <p className='font-bold'>2. What role do federal agencies play in GPA's cybersecurity strategy, and how has
                          their involvement shaped GPA’s approach to protecting critical infrastructure?</p>
                      <br />
                      GPA has developed key federal partnerships to assist with its cyber and physical security
                      readiness and resilience. Cybersecurity special entities and other federal agencies provide
                      audits, training, real-time monitoring, and access to advanced tools and resources.
                      <br />
                      <br />
                      Collaborative initiatives, including CyberStrike exercises and quarterly meetings with
                      NAVFAC IT, enhance GPA’s situational awareness and strategic readiness. These
                      partnerships ensure GPA meets national standards, stays ahead of emerging threats, and
                      delivers reliable, secure energy to the people of Guam.
                      <br />
                      <br />
                      {/* ------------------------------------------- */}
                      <p className='font-bold'>3. What specific measures has GPA implemented recently to enhance both cyber and
                          physical security, and how are these efforts aligned with national security standards?</p>
                      <br />
                      GPA has implemented significant measures to strengthen both cyber and physical security.
                      Key upgrades include enhanced access control measures at critical facilities and investing in
                      advanced cybersecurity tools such as multi-factor authentication and threat detection
                      systems.
                      <br />
                      <br />
                      GPA’s IT staff hold highly valued GICSP, CISA, CCNA, CCNP and CompTIA Security+
                      certifications. Regular federal audits by cybersecurity special entities and other federal
                      agencies ensure that potential vulnerabilities are identified for GPA to address, while smart
                      grid security protocols, in place since 2015, enhance overall resilience. GPA also enforces
                      strict cybersecurity requirements for its suppliers, ensuring new projects meet high security
                      standards.
                      <br />
                      <br />
                      {/* ------------------------------------------- */}
                      <p className='font-bold'>4. How does GPA balance transparency with the need to protect sensitive information
                          when discussing cybersecurity issues with the public?</p>
                      <br />
                      GPA carefully balances the need for transparency with the responsibility to protect sensitive
                      information about its cybersecurity efforts. By sharing general updates, such as
                      participation in initiatives like CyberStrike training, GPA reassures the public of its proactive
                      measures while avoiding disclosures that could compromise security.
                      <br />
                      <br />
                      Collaboration with federal agencies ensures that all public messaging aligns with national
                      security protocols, maintaining consistency and compliance. Through visible actions and
                      adherence to recognized cybersecurity standards, GPA fosters public trust while
                      safeguarding the integrity of Guam’s energy infrastructure.
                      <br />
                      <br />
                      {/* ------------------------------------------- */}
                      <p className='font-bold'>5. Does GPA have enough resources to address cyber threats?</p>
                      <br />
                      GPA effectively addresses cyber threats through resourcefulness and strategic partnerships
                      with federal agencies. These collaborations provide access to tools, expertise, and additional
                      support to strengthen GPA’s defenses.
                      <br />
                      <br />
                      GPA’s cybersecurity team comprises highly skilled, certified professionals who remain
                      adaptable to evolving threats. Investments in critical infrastructure, such as GPA’s
                      independent fiber-optic network, enhance reliability and reduce vulnerabilities.
                      <br />
                      <br />
                      GPA’s proactive cybersecurity program has been in place since 2015, long before recent
                      high-profile threats, demonstrating years of commitment and strategic planning. With
                      several million dollars in cybersecurity grants currently managed, GPA continues to reinforce
                      its ability to protect Guam’s energy systems through targeted investments and innovative
                      measures.
                      <br />
                      <br />
                      {/* ------------------------------------------- */}
                      <p className='font-bold'>6. Why did GPA choose to build its own fiber-optic network instead of working with
                          existing telecommunications companies?</p>
                      <br />
                      GPA opted to build its own fiber-optic network as part of its commitment to resourcefulness
                      and long-term system security. By reducing reliance on third-party telecommunications
                      providers, GPA minimizes exposure to external vulnerabilities and ensures full control over its
                      critical communications infrastructure.
                      <br />
                      <br />
                      This decision significantly enhances cybersecurity and operational reliability, providing
                      secure and consistent data integrity across all substations. The independent network aligns
                      with GPA’s broader mission to protect critical systems and deliver dependable energy
                      services to the residents of Guam.
                      <br />
                      <br />
                      {/* ------------------------------------------- */}
                      <p className='font-bold'>7. How does GPA ensure reliable energy for Guam’s residents and the Navy as a
                          strategic partner?</p>
                      <br />
                      GPA plays a critical role in supporting both the island’s residents and its strategic partners,
                      including the U.S. Navy, which consumes approximately 20% of Guam’s energy. GPA ensures
                      uninterrupted power delivery to meet the operational needs of the Navy while maintaining
                      reliable service for families and businesses across the island.
                      <br />
                      <br />
                      The Authority’s infrastructure and cybersecurity measures are designed to balance the
                      diverse energy demands of its customers. Collaborative efforts with federal agencies help
                      GPA adhere to strict national standards, ensuring a resilient grid capable of supporting both
                      military and civilian use.
                      <br />
                      <br />
                      Ongoing investments in infrastructure, advanced cybersecurity systems, and overall system
                      resilience allow GPA to consistently deliver secure and reliable energy for all its customers,
                      reinforcing its commitment to Guam’s community and strategic importance.
                  </Details>
              </section>
        <section className='hidden' id="contact-us">
          <H1>FAQs</H1>
          <Details className="flex flex-col">
            {/* {sop?.map((sopItem) => {
              return (
                <a
                  className="text-orange"
                  href={`${API}${sopItem.attributes.File.data.attributes.url}`}
                  key={sopItem.id}
                  rel="noreferrer"
                  target="_blank"
                >
                  {sopItem.attributes.Title}
                </a>
              );
            })} */}
            <UnorderedList>
              {reports?.map((value) => {
                if (value.attributes.File.data.attributes.url !== '') {
                  return (
                    <List
                      className="text-orange mb-4"
                      key={value.attributes.File.data.attributes.url}
                    >
                      <a
                        href={`${API}${value.attributes.File.data.attributes.url}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {value.attributes.Title}
                      </a>
                    </List>
                  );
                }

                return (
                  <>
                    {/* {file
                            .filter((fileData) => fileData.attributes.AnchorText === value.anchor)
                            .map((fileData) => {
                              return (
                                <>
                                  <List className="text-orange" key={value.anchor}>
                                    <a
                                      className="text-orange"
                                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {value.anchor}
                                    </a>
                                  </List>
                                </>
                              );
                            })} */}
                  </>
                );
              })}
            </UnorderedList>
            {
                reports === null
                ? <p className="">
                    No data to be displayed
                </p>
                : <></>
            }
          </Details>
        </section>
      </ContentContainer>
    </>
  );
};
